import { PropsWithChildren } from 'react'

import styles from './ErrorPage.module.css'

/**
 * A generic Error Page, intended to be used as a Route errorElement.
 * @see https://reactrouter.com/en/main/route/error-element
 */
const ErrorPage = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles.container}>
      <div className={styles.errorPageImage} />
      {children ? (
        <div>{children}</div>
      ) : (
        <div>
          <h1>An error has occured</h1>
          <p>Our developers have been notified</p>
        </div>
      )}
    </div>
  )
}

export default ErrorPage
