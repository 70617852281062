import useSWR from 'swr'

import { CompanyResponse } from '~/types'

const useCompany = () => {
  const swr = useSWR<CompanyResponse>('users/me/company')
  return swr
}

export default useCompany
