import { Bifrost, enUS } from '@intility/bifrost-react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { SWRConfig } from 'swr'

import { authorizedFetch } from './auth'
import Authentication from './auth/Authentication'
import constants from './constants'
import './index.css'
import { router } from './router'
import { initializeSentry } from './utils/initializeSentry'
import registerHotJarScript from './utils/registerHotJarScript'

// https://bifrost.intility.com/#/Welcome%20to%20Bifrost/Get%20started/Installation
import '@intility/bifrost-react/dist/bifrost-app.css'

// Set default theme to dark if no theme preference is set
if (!localStorage?.getItem('bfTheme')) {
  localStorage?.setItem('bfTheme', '"dark"')
}

const rootElement = document.getElementById('root')!
const root = createRoot(rootElement)

const swrConfigValue = {
  fetcher: (path: string) =>
    authorizedFetch(`${constants.server_url}/${path}`).then(response =>
      response.json()
    ),
  focusThrottleInterval: 60000,
  errorRetryInterval: 60000
}

root.render(
  <StrictMode>
    <Authentication>
      <SWRConfig value={swrConfigValue}>
        <Bifrost locale={enUS}>
          <RouterProvider router={router} />
        </Bifrost>
      </SWRConfig>
    </Authentication>
  </StrictMode>
)

if (navigator.doNotTrack !== '1') {
  registerHotJarScript()
  initializeSentry()
}
