import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { Grid, Icon, Message } from '@intility/bifrost-react'

import Header from '~/components/Header'
import useApps from '~/hooks/useApps'
import styles from './Applications.module.css'
import ManagementApps from './components/ManagementApps'
import OtherApps from './components/OtherApps'
import ToolsApps from './components/ToolsApps'

const Applications = () => {
  const { error } = useApps()

  return (
    <>
      <Header title='Platform Manager Applications' />
      <div>
        <ToolsApps />
        <div className={styles.servicesContainer}>
          {error && (
            <Message state='warning' className={styles.errorMessage}>
              <Icon
                icon={faExclamationCircle}
                className='bfc-warning'
                marginRight
                fixedWidth
              />
              We have encountered a problem retrieving your access rights. This
              is only cosmetical, all links should work as normal.
            </Message>
          )}

          <Grid small={2} medium={4} gap={24} className={styles.appsGrid}>
            <ManagementApps />
            <OtherApps />
          </Grid>
        </div>
      </div>
    </>
  )
}

export default Applications
