import { wrapCreateBrowserRouter } from '@sentry/react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route
} from 'react-router-dom'

import ErrorPage from './components/ErrorPage'
import Navigation from './components/Navigation'
import Contacts from './pages/Contacts/Contacts'
import Applications from './pages/Dashboard/Applications'

/**
 * Sentry integration
 * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 */
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

/**
 * The applications router, using react-router data APIs
 * @see https://reactrouter.com/en/main/routers/create-browser-router
 */
const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      errorElement={<ErrorPage />}
      element={
        <Navigation>
          <Outlet />
        </Navigation>
      }
    >
      <Route path='/' element={<Applications />} />
      <Route path='/contacts' element={<Contacts />} />
    </Route>
  )
)

export { router }
