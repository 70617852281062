// Replaces digits with itself with spaces in between

const formatPhoneNumber = (input: string) => {
  if (!input) return input
  const stripped = input.replace(' ', '')

  // Norway
  if (stripped.startsWith('+47') && stripped.length === 11) {
    if (stripped.slice(3).startsWith('8')) {
      return stripped.replace(/(\d{2})(\d{3})(\d{2})(\d{3})/, '$1 $2 $3 $4')
    }
    return stripped.replace(
      /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
      '$1 $2 $3 $4 $5'
    )
  }

  // Add others if needed

  return input
}

export default formatPhoneNumber
