import missingDataImageDark from '../assets/missingData/clueless_dark.png'
import missingDataImageLight from '../assets/missingData/clueless_light.png'
import styles from './MissingData.module.css'

const MissingData = ({ message }: { message?: string }) => {
  return (
    <div className={styles.missingDataContainer}>
      <img
        className={styles.missingDataImage + ' bf-dark-only'}
        src={missingDataImageDark}
        alt=''
      />
      <img
        className={styles.missingDataImage + ' bf-light-only'}
        src={missingDataImageLight}
        alt=''
      />
      <em className='bfc-base-2 bf-large'>{message}</em>
    </div>
  )
}

export default MissingData
