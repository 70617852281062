import { Grid, Skeleton } from '@intility/bifrost-react'

import useContacts from '~/api/useContacts'
import ErrorPage from '~/components/ErrorPage'
import Header from '~/components/Header'
import MissingData from '~/components/MissingData'
import styles from './Contacts.module.css'
import ContactCard, { SkeletonCard } from './components/ContactCard'

const Contacts = () => {
  const { data: contacts, isLoading, error } = useContacts()

  if (error) {
    return (
      <>
        <Header title='Your Intility contacts' />
        <div className={styles.errorContainer}>
          <ErrorPage>
            <h4>
              We have encountered a problem retrieving your Intility contacts.
              Refresh to try again or contact{' '}
              <a
                href='https://my.intility.com/support'
                target='_blank'
                rel='noreferrer'
              >
                support
              </a>{' '}
              if the problem persists.
            </h4>
          </ErrorPage>
        </div>
      </>
    )
  }

  return (
    <>
      <Header
        title='Your Intility contacts'
        // breadcrumbs={[{ name: 'Intility contacts' }]}
      />
      <div className={styles.contactsContainer}>
        <Grid medium={2} gap={24}>
          {!contacts && isLoading && (
            <Skeleton repeat={6}>
              <SkeletonCard />
            </Skeleton>
          )}
          {contacts &&
            contacts.length > 0 &&
            !isLoading &&
            contacts.map(contact => (
              <ContactCard key={contact.name} contact={contact} />
            ))}
        </Grid>
      </div>

      {/* No data */}
      {contacts && contacts?.length === 0 && !isLoading && (
        <MissingData message='Wops, no contacts found' />
      )}
    </>
  )
}

export default Contacts
