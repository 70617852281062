import { Box, Grid, Label, Skeleton } from '@intility/bifrost-react'

import teamsLogo from '~/assets/icons/microsoft-teams.webp'
import { ContactType } from '~/types'
import formatPhoneNumber from '~/utils/formatPhoneNumber'
import styles from './ContactCard.module.css'

const ContactCard = ({ contact }: { contact: ContactType }) => {
  const { name, role, email, phone, profilePhoto, descriptions } = contact

  return (
    <Box className={styles.contactCard} shadow radius>
      <Grid small={2} gap={0} className={styles.contactCardGrid}>
        <Grid gap={0} className={styles.leftSide}>
          <div className={styles.contactDetails}>
            <div className={styles.contactImage}>
              <img
                src={`data:profilePhoto/png;base64, ${profilePhoto}`}
                alt='Profile'
              />
              {email && (
                <a
                  className={styles.teamsChat}
                  href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`}
                >
                  <img src={teamsLogo} alt='Microsoft Teams' />
                </a>
              )}
            </div>
            <div className={styles.nameRole}>
              <div className={styles.contactName}>{name}</div>
              <div className={styles.contactRole + ' bfc-base-2'}>{role}</div>
            </div>
          </div>
          <div className={styles.contactChannels}>
            {email && (
              <a className='bf-title-link' href={`mailto:${email}`}>
                {email}
              </a>
            )}
            {phone && (
              <a className='bf-title-link' href={`tel:${phone}`}>
                {formatPhoneNumber(phone)}
              </a>
            )}
          </div>
        </Grid>

        <div className={styles.rightSide}>
          <Label>Responsibilities</Label>
          <div className={styles.responsibilitesList + ' bf-scrollbar-small'}>
            <ul>
              {descriptions.map((responsibility, index) => (
                <li key={index}>{responsibility}</li>
              ))}
            </ul>
          </div>
        </div>
      </Grid>
    </Box>
  )
}

const SkeletonCard = () => {
  return (
    <Box className={styles.contactCard} shadow radius>
      <Grid small={2} gap={0}>
        <Grid gap={0} className={styles.leftSide}>
          <div className={styles.contactDetails}>
            <div className={styles.contactImage}>
              <Skeleton.Rect width='52px' height='52px' />
            </div>
            <div className={styles.nameRole}>
              <div className={styles.contactName}>
                <Skeleton.Text width='180px' />
              </div>
              <div className={styles.contactRole}>
                <Skeleton.Text width='80px' />
              </div>
            </div>
          </div>
          <div className={styles.contactChannels}>
            <span>
              <Skeleton.Text width='200px' />
            </span>
            <span>
              <Skeleton.Text width='100px' />
            </span>
          </div>
        </Grid>

        <div className={styles.rightSide}>
          <Label>Responsibilities</Label>
          <ul>
            <li>
              <Skeleton.Text width='100px' />
            </li>
            <li>
              <Skeleton.Text width='80px' />
            </li>
            <li>
              <Skeleton.Text width='120px' />
            </li>
          </ul>
        </div>
      </Grid>
    </Box>
  )
}

export default ContactCard
export { SkeletonCard }
