import classNames from 'classnames'
import { Breadcrumbs } from '@intility/bifrost-react'
import { NavLink } from 'react-router-dom'

import styles from './Header.module.css'

export type Crumb = {
  path?: string
  name: string
}

const Header = ({
  title,
  description,
  breadcrumbs
}: {
  title: string
  description?: string
  breadcrumbs?: Crumb[]
}) => {
  return (
    <div className={styles.headerSection}>
      <div className={classNames(styles.headerContent + ' bf-page-padding')}>
        {breadcrumbs && (
          <Breadcrumbs>
            <Breadcrumbs.Item>
              <NavLink to='/'>Home</NavLink>
            </Breadcrumbs.Item>
            {breadcrumbs.map(({ path, name }) => (
              <Breadcrumbs.Item key={name}>
                {path ? <NavLink to={path}>{name}</NavLink> : name}
              </Breadcrumbs.Item>
            ))}
          </Breadcrumbs>
        )}
        <h2>{title}</h2>
        {description && <div>{description}</div>}
      </div>
    </div>
  )
}
export default Header
