import {
  faBrowsers,
  faBuilding,
  faSignOutAlt,
  faUsers
} from '@fortawesome/pro-solid-svg-icons'
import { Checkbox, Nav, Theme, useApplyTheme } from '@intility/bifrost-react'
import { NavLink } from 'react-router-dom'
import useLocalStorageState from 'use-local-storage-state'

import useCompany from '~/api/useCompany'
import { logout } from '~/auth/instance'
import styles from './Navigation.module.css'
import ProfilePicture from './ProfilePicture'

const Navigation = ({ children }: React.PropsWithChildren) => {
  // persist theme state in local storage.
  // you might want to use a cookie or a database instead?
  const [theme, setTheme] = useLocalStorageState<Theme>('bfTheme', {
    defaultValue: 'system'
  })
  // keep document theme in sync with state
  useApplyTheme(theme)

  // Company info
  const { data: company } = useCompany()

  return (
    <Nav
      className='bf-elements'
      logo={
        <a href='/' className='bf-neutral-link'>
          <Nav.Logo logo='/assets/apps/platformManager.svg'>
            <span className='to-small'>Platform Manager</span>
            <span className='from-small'>Intility Platform Manager</span>
          </Nav.Logo>
        </a>
      }
      top={
        <>
          {company && (
            <Nav.Item icon={faBuilding}>
              <span className={styles.companyName}>{company?.companyName}</span>
            </Nav.Item>
          )}

          <Nav.Group
            name={<ProfilePicture size={28} />}
            className={styles.profileDropdown}
          >
            <a href='https://my.intility.com'>
              <Nav.Item hideCircle>
                <img
                  src='/assets/apps/myIntility.svg'
                  alt=''
                  style={{ width: '21px', height: '21px' }}
                />
                <span style={{ marginLeft: '8px' }}>{'My Intility'}</span>
              </Nav.Item>
            </a>

            <hr />

            <section>
              <Nav.Header>Color theme</Nav.Header>
              <Checkbox
                type='radio'
                label='Dark'
                name='color-theme'
                checked={theme === 'dark'}
                onChange={() => setTheme('dark')}
              />
              <Checkbox
                type='radio'
                label='Light'
                name='color-theme'
                checked={theme === 'light'}
                onChange={() => setTheme('light')}
              />
              <Checkbox
                type='radio'
                label='System'
                name='color-theme'
                checked={theme === 'system'}
                onChange={() => setTheme('system')}
              />
            </section>

            <hr />

            <button onClick={() => logout()}>
              <Nav.Item icon={faSignOutAlt} hideCircle>
                Sign out
              </Nav.Item>
            </button>
          </Nav.Group>
        </>
      }
      side={
        <>
          <NavLink to='/'>
            <Nav.Item icon={faBrowsers}>Applications</Nav.Item>
          </NavLink>
          <NavLink to='/contacts'>
            <Nav.Item icon={faUsers}>Contacts</Nav.Item>
          </NavLink>
        </>
      }
    >
      {children}
    </Nav>
  )
}

export default Navigation
